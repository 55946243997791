/* colors */

$footer-top-bg: #f7de84;
$footer-bottom-bg: #8c0f1a;

/* FOOTER */

.reset-ul{
    list-style: none;
    padding: 0;
    margin: 0; 
}

.top-footer {
    background-color: $footer-top-bg;
    padding: 25px 0;

    .img-responsive.img-responsive {
        display: inline-block;
    }
}

.botoom-footer {
    background-color: $footer-bottom-bg;
    padding: 40px 0;
    color: #fff;


    @media screen and (max-width:991px){
        .col-md-3{
            text-align: center;
            padding: 30px 0;
        }

        .social-list{
            text-align: center;
        }
    }
}

.social-list {
    @extend .reset-ul;
    text-align: right;

    li{
        display: inline-block;    
    }

    a {
        color: #fff;
        padding: 0 10px;
    }
}

.pay-list {
    @extend .reset-ul;
    text-align: center;

    @media screen and (max-width: 767px) {
        li{
            margin: 25px 0;
        }

    }

    @media screen and (min-width: 768px) {
        li{
            display: inline-block;
            padding: 0px 10px;
        }

    }

    img.svg, svg {
        width: 41px;
        fill: #fff;
        height: 27px;
    }
}


.scroll-top {
    position: fixed;
    bottom: 10px;
    right: 20px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: $footer-top-bg;
}

.col{
    float: left;
    width: 17%;
}

.cols-md{
    width: 18%;
    margin: 0 2%;
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

